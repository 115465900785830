
  export default {
    name: 'SocialIcon',
    props: {
      color: {
        type: String,
        default: undefined,
      },
      footerSocials: {
        type: Array,
        default: () => [],
      },
      loggedIn: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    serverCacheKey(props) {
      // Deaktiviere das Caching, wenn der Benutzer eingeloggt ist
      if (props.loggedIn) {
        return false;
      }

      // Ansonsten generiere einen eindeutigen Cache-Schlüssel
      return 'social-icons';
    },
    data() {
      return {
        socialNavigation: {
          socials: [
            {
              id: 44,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Facebook',
              icon: 'fab fa-facebook-f',
              class: 'white--text',
              href: 'https://www.facebook.com/promotionbasis',
              target: '_blank',
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            {
              id: 45,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Instagram',
              icon: 'fab fa-instagram',
              class: 'white--text',
              href: 'https://www.instagram.com/promotionbasis/',
              target: '_blank',
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            {
              id: 46,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'X',
              icon: 'fab fa-x-twitter',
              class: 'white--text',
              href: 'https://twitter.com/promotionbasis',
              target: '_blank',
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            {
              id: 120,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Google',
              icon: 'fab fa-google',
              class: 'white--text',
              href: 'https://www.google.com/maps/place/promotionbasis.de+(PB)/@54.3146046,10.1278915,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x3795963811a57b2f!8m2!3d54.3146046!4d10.1300802?authuser=1&shorturl=1',
              target: '_blank',
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            {
              id: 232,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'TikTok',
              icon: 'fab fa-tiktok',
              class: 'white--text',
              href: 'https://www.tiktok.com/@promotionbasis',
              target: '_blank',
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            {
              id: 239,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'Youtube',
              icon: 'fab fa-youtube',
              class: 'white--text',
              href: 'https://www.youtube.com/@pbteam9626',
              target: '_blank',
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
            {
              id: 240,
              Text: null,
              Url: null,
              Target: null,
              Title: null,
              Icon: null,
              text: 'LinkedIn',
              icon: 'fab fa-linkedin',
              class: 'white--text',
              href: 'https://www.linkedin.com/company/promotionbasis',
              target: '_blank',
              route: null,
              page: null,
              article: null,
              activity: null,
              city: null,
              title: null,
            },
          ],
        },
        socials: [],
      };
    },
    mounted() {
      if (this.footerSocials && this.footerSocials.length > 0) {
        this.footerSocials.forEach((menuItem) => {
          // prepare main menu item
          const socialItem = this.prepareMenuItem(menuItem);
          this.socials.push(socialItem);
        });
      }
    },
    async created() {
      /* Json für Navigation zunächst statisch und nicht mehr dynamisch aus Strapi!
      const footerNavigation = await this.$cachedStrapi
        .find('footer-navigation')
        .catch((e) => {
          if (e.statusCode !== 404) {
            throw e;
          }
        });
       */

      if (
        this.socialNavigation.socials &&
        this.socialNavigation.socials.length > 0
      ) {
        this.socialNavigation.socials.forEach((menuItem) => {
          // prepare main menu item
          const socialItem = this.prepareMenuItem(menuItem);
          this.socials.push(socialItem);
        });
      }
    },
    methods: {
      prepareMenuItem(item) {
        const menuItem = {
          name: item.text,
        };

        // prepare route for menu item
        let route = null;
        if (item.route) {
          route = item.route;
        } else if (item.page) {
          route = {
            name: item.page.slug,
          };
        } else if (item.article) {
          route = {
            name: 'articles-category-article',
            params: {
              category: item.article.article_category.slug,
              article: item.article.slug,
            },
          };
        } else if (item.activity) {
          route = {
            name: 'landingpages-activities-activity',
            params: {
              activity: item.activity.slug,
            },
          };
        } else if (item.city) {
          route = {
            name: 'landingpages-cities-city',
            params: {
              city: item.city.slug,
            },
          };
        } else if (item.href) {
          menuItem.href = item.href;
          menuItem.target = item.target;
        }

        menuItem.route = route;

        menuItem.class = item.class;
        menuItem.icon = item.icon;
        menuItem.title = item.title;

        return menuItem;
      },
    },
  };
